<template>
  <v-container>
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <error
          :width="540"
          type="page_not_found"
          title="Maaf, Halaman Ini Sedang Tidak Tersedia"
          text="Kami sedang memperbaiki e-tix. Silahkan tunggu beberapa saat dan kembali lagi pada layanan kami."
        />
        <div class="d-flex justify-center">
          <v-btn color="primary" :to="{ name: 'main' }">
            Kembali ke Beranda
          </v-btn>
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import Error from "@/components/error/ErrorComponent.vue";
export default {
  components: {
    Error,
  },
};
</script>

<style>
</style>