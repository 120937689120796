<template>
  <v-breadcrumbs :items="items">
    <template v-slot:divider>
      <v-icon icon="mdi-chevron-right"></v-icon>
    </template>
  </v-breadcrumbs>

  <v-container fluid v-if="isLoading">
    <div :style="{ height: '80vh' }" class="d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </v-container>

  <v-container v-else fluid>
    <v-row>
      <v-col>
        <div class="d-flex flex-wrap justify-space-between">
          <v-chip-group v-model="filter.category" class="me-auto">
            <v-chip
              v-for="(item, index) in categories"
              :key="index"
              :value="item.productcategory_id"
              class="mx-2 text-none"
              selected-class="bg-primary"
              variant="outlined"
              rounded="sm"
            >
              {{ item.productcategory_name }}
            </v-chip>
          </v-chip-group>

          <div class="d-flex justify-space-between ma-3">
            <v-btn
              @click="dialog.state = true"
              class="text-none mx-2"
              prepend-icon="mdi-tune"
              variant="outlined"
              >Filter</v-btn
            >
            <div>
              <v-select
                v-model="sortSelection"
                hide-details
                prepend-inner-icon="mdi-sort-ascending"
                label="Urut berdasarkan"
                density="compact"
                :items="sort"
                item-title="name"
                item-value="state"
                variant="outlined"
              ></v-select>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="products.length > 0" justify="start">
      <template v-for="(product, index) in products" :key="index">
        <v-col class="mx-auto" cols="auto">
          <non-event-card
            class="bnw me-auto"
            :product="product"
          ></non-event-card>
          <event-card class="bnw me-auto" :product="product"></event-card>
        </v-col>
      </template>
    </v-row>

    <v-row v-if="products.length > 0">
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-pagination
          v-model="this.page"
          :length="paginationLength"
        ></v-pagination>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <no-product v-if="products.length <= 0 && !isLoading"></no-product>
  </v-container>

  <filter-product
    :filterEventDate="false"
    :locationData="locationData"
    v-model:dialog="dialog.state"
    @filtering="filtering"
  ></filter-product>
</template>

<script>
import NoProduct from "@/components/Negative/NoProduct.vue";
import NonEventCard from "@/components/Cards/NonEventCard.vue";
import EventCard from "@/components/Cards/EventCard.vue";
import FilterProduct from "@/components/FilterProduct.vue";

export default {
  components: {
    NonEventCard,
    EventCard,
    NoProduct,
    FilterProduct,
  },

  async created() {
    this.sort = this.$route.meta?.sort;

    const categories = await this.axios.get("product/categories");

    this.categories = categories.data.response.filter(
      (category) => category?.parent_category?.productcategory_id == category
    );

    this.axios
      .get("/product/own/city", {
        params: {
          page: 1,
          pageSize: 1500,
        },
      })
      .then((response) => {
        this.locationData = response.data.response.cities.map((city) =>
          city.locationcity_name
            .toLowerCase()
            .replace(/\b\w/g, (s) => s.toUpperCase())
        );
      });

    this.items = this.$route.meta?.breadcrumb;
    this.getProducts({
      page: this.page,
      pageSize: this.pageSize,
    });
  },

  data() {
    return {
      page: 1,
      pageSize: 60,
      paginationLength: 1,
      rules: {
        filterPrice: [
          (value) => {
            return (
              !value[0] ||
              !value[1] ||
              parseInt(value[0]) <= parseInt(value[1]) ||
              "Harga minimum lebih besar dari maximum"
            );
          },
        ],
      },
      isLoading: true,
      items: [],

      sort: [],
      sortSelection: { order: "desc" },
      min: 0,
      max: 4500000,
      locationData: [
        "Jakarta",
        "Bekasi",
        "Surabaya",
        "Medan",
        "Bandung",
        "Depok",
        "Bali",
        "Palembang",
        "Yogyakarta",
        "Tangerang",
        "Makassar",
        "Balikpapan",
        "Semarang",
        "Cikarang",
        "Padang",
      ],
      categories: [],
      category: null,
      filter: {
        category: null,
        priceLow: null,
        priceHigh: null,
        city: [],
      },
      dialog: {
        state: false,
      },
      products: [],
    };
  },

  watch: {
    sortSelection: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.refreshProduct();
        }
      },
    },

    category: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.refreshProduct();
        }
      },
    },
  },

  methods: {
    filtering(newValue) {
      if (newValue.price.length) {
        this.filter.priceLow = newValue.price[0];
        this.filter.priceHigh = newValue.price[1];
      }
      this.filter.city = newValue.location;
      this.filter.startDate = newValue.startDate;
      this.filter.endDate = newValue.endDate;
      this.refreshProduct();
    },
    getMaxDate() {
      const date = new Date();
      return date.toISOString().split("T")[0];
    },
    refreshProduct() {
      const { order, sort } = this.sortSelection;
      this.getProducts({
        page: 1,
        pageSize: this.pageSize,
        sort: sort,
        order: order,
        category: this.category,
        priceLow: this.filter.priceLow,
        priceHigh: this.filter.priceHigh == 0 ? null : this.filter.priceHigh,
        city: this.filter.city.length > 0 ? this.filter.city.join(",") : null,
        dateEnd: this.filter.endDate,
      });
    },
    async getProducts({
      page,
      pageSize,
      category,
      sort,
      order,
      priceLow,
      priceHigh,
      city,
      dateEnd,
    }) {
      this.isLoading = true;
      await this.axios
        .get("product/upcoming", {
          params: {
            page: page,
            pageSize: pageSize,
            category: category,
            priceLow: priceLow || null,
            priceHigh: priceHigh || null,
            sortby: sort,
            order: order,
            city: city,
            dateEnd: dateEnd,
          },
        })
        .then((response) => {
          this.isLoading = false;
          const data = response.data.response;
          const products = data.products;

          const soldOut = products.filter((product) => {
            if (product.quantity <= 0 && product.is_limited) {
              return product;
            }
          });
          const ready = products.filter((product) => {
            if (product.quantity > 0 || !product.is_limited) {
              return product;
            }
          });

          this.paginationLength = Math.ceil(
            response.data.response.count / this.pageSize
          );
          this.products = [...ready, ...soldOut];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    toDetail(id) {
      this.$router.push(`/detail/${id}`);
    },
  },
};
</script>

<style scoped>
.size-select {
  max-width: 10rem;
}
.bnw {
  filter: grayscale(100%);
}
</style>